import React, {Component, Fragment} from 'react';
import FormInputCheckboxElement from './FormInputCheckboxElement';
import classnames from "classnames";

class FormInputCheckbox extends Component {
    render () {
        const { name, value, label, helpText, valueOptions, selectedCheckboxes, handleCheckboxChange, error } = this.props;
        let optionsValue = '';
        if (typeof(value) !== 'undefined') optionsValue = value.toString();

        let optionTitle='';
        let prevOptionTitle='';
        return (
            <div className="form-group row border-bottom pb-2">
                <label htmlFor={name} className="col-sm-4">{label}</label>
                <div className="col-sm-8">
                    {valueOptions ? (
                            valueOptions.map((options, index) => {
                                if (typeof(options.WS) !== 'undefined') {
                                    if (name === 'segment' && parseInt(options.WS) === 0 && prevOptionTitle !== 'Freightliner Segments') {
                                        optionTitle = 'Freightliner Segments';
                                        prevOptionTitle = optionTitle;
                                    } else if (name === 'segment' && parseInt(options.WS) === 0 ) {
                                        optionTitle = '';
                                    } else if (name === 'segment' && parseInt(options.WS) === 1 && prevOptionTitle !== 'WesternStar Segments') {
                                        optionTitle = 'WesternStar Segments';
                                        prevOptionTitle = optionTitle;
                                    } else if (name === 'segment' && parseInt(options.WS) === 1) {
                                        optionTitle = '';
                                    }
                                }
                                if (typeof(options.id) !== 'undefined') {
                                    return (
                                        <Fragment key={index}>
                                            {optionTitle && <h3>{optionTitle}</h3> }
                                            <div className="form-check form-check-inline">
                                                <label className="form-check-label" htmlFor={name + index}>
                                                    <FormInputCheckboxElement
                                                        className={classnames('form-check-input', {'is-invalid': error})}
                                                        type="checkbox"
                                                        checked={optionsValue.indexOf("," + options.id.toString() + ",") !== -1 || options.id === value ? 'checked' : null}
                                                        name={name}
                                                        id={name + index}
                                                        value={options.id}
                                                        selectedCheckboxes={selectedCheckboxes}
                                                        handleCheckboxChange={handleCheckboxChange}
                                                    />
                                                    {options.name}
                                                </label>
                                            </div>
                                    </Fragment>);
                                } else if (typeof (options.ID) !== 'undefined') {
                                    return (
                                        <Fragment key={index}>
                                            {optionTitle && <h3>{optionTitle}</h3> }
                                            <div className="form-check form-check-inline">
                                                <label className="form-check-label" htmlFor={name + index}>
                                                    <FormInputCheckboxElement
                                                        className={classnames('form-check-input', {'is-invalid': error})}
                                                        type="checkbox"
                                                        checked={optionsValue.indexOf("," + options.ID.toString() + ",") !== -1 || options.ID === value ? 'checked' : null}
                                                        name={name}
                                                        id={name + index}
                                                        value={options.ID}
                                                        selectedCheckboxes={selectedCheckboxes}
                                                        handleCheckboxChange={handleCheckboxChange}
                                                    />
                                                    {options.Name}
                                                </label>
                                            </div>
                                        </Fragment>
                                    );
                                } else {
                                    return('no id found');
                                }
                            }))
                        : null
                    }
                </div>
                {helpText && <small id={name+"Help"} className="col-12 form-text text-muted">{helpText}</small>}
                {error && <div className="col-12 alert alert-danger"><i className="fas fa-arrow-alt-circle-up" /> {error}</div> }
            </div>
        )
    }
}
export default FormInputCheckbox
