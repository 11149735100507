export const API_URL  = 'https://factqa.freightlinertrucks.com/apicp';

// DASHBOARD FUNCTIONS
export const GET_TABLE_DATA = 'GET_TABLE_DATA';

// PAGES
export const GET_CONFIG = 'GET_CONFIG';
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const GET_PUBLISH_SITE = 'GET_PUBLISH_SITE';
export const GET_DATA_CATEGORIES = 'GET_DATA_CATEGORIES';

// USERS
export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const ADD_USER = 'ADD_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';

export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const GET_ERRORS = 'GET_ERRORS';
