import React, {Fragment} from 'react';
import classnames from 'classnames';

const FormInputSelect = ({name, value, defaultValue, label, placeholder, type, helpText, dropdown, onChange, error}) => {
    return (
        <div className="form-group row border-bottom pb-2">
            <label className="col-sm-4" htmlFor={name}>{label}</label>
            <div className="col-sm-8">
                <select
                    className={classnames('form-control form-control-lg', { 'is-invalid': error})}
                    id={name}
                    name={name}
                    value={value}
                    onChange={onChange}
                >
                    <option value="">-- SELECT --</option>
                    {dropdown &&  <Fragment>
                        {typeof(dropdown.length) !== 'undefined' && <Fragment>
                            {dropdown.length > 0 && <Fragment>
                                {dropdown.map((options, index) =>
                                    <Fragment key={index}>
                                        {options.Name !== '' && typeof(options.Name) != 'undefined' && <option value={options.ID}>{options.Name}</option>}
                                        {options.name !== '' && typeof(options.name) != 'undefined' && <option value={options.id}>{options.name}</option>}
                                        {(options.Name === '' || typeof(options.Name) === 'undefined') && (options.name === '' && typeof(options.name) === 'undefined') && <option value={index}>{options}</option>}
                                    </Fragment>
                                )}
                            </Fragment>}
                        </Fragment>}
                    </Fragment>}
                </select>
            </div>
            {helpText && <small id={name + "Help"} className="col-12 form-text text-muted">{helpText}</small>}
            {error && <div className="col-12 alert alert-danger"><i className="fas fa-arrow-alt-circle-up" /> {error}</div>}
        </div>
    )
};

export default FormInputSelect