import React, { Component, Fragment } from 'react';
import { Provider } from 'react-redux';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import store from './client/store';
import {PageView, initGA} from './components/tracking/GATracking';
import {GACode, apiURL, apiKey} from './config';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/site.min.css';
import "react-image-gallery/styles/css/image-gallery.css";

// TEMPLATES
import HeaderTemplate from './components/layout/Header';
import FooterTemplate from './components/layout/Footer';

// Pages
import ContentPage from './components/pages/ContentPage';
import LandingPage from './components/pages/LandingPage';
import FormPage from './components/pages/FormPage';
import Error404 from './components/pages/Error404';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            routes: [],
            config: {},
        };
    };
    componentDidMount() {
        initGA(GACode);
        PageView();

        // AXIOS HERE
        axios.get(apiURL + 'getConfig', {params: {apiKey: apiKey}})
            .then( (response) => {
                if (typeof(response.data.routes) !== 'undefined') {
                    this.setState({
                        "routes": response.data.routes,
                        "config": response.data.config
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    render() {
        const { routes, config, rotatingHeader } = this.state;
        if (typeof(config.id) !== 'undefined') {
            return (
                <Provider store={store}>
                    <Router>
                        <Fragment>
                            <HeaderTemplate routes={routes} pageConfig={config}/>
                            <Switch>
                                {routes.map((route, index) => {
                                    let routeType = '';
                                    if (route.pageType === '' || typeof (route.pageType) === 'undefined') {
                                        routeType = "content";
                                    } else {
                                        routeType = route.pageType;
                                    }
                                    if (routeType === 'Content Page') {
                                        return (<Route key={index} exact path={route.linkUrl} component={() => <ContentPage pageConfig={config} path={window.location.pathname} />} />);
                                    } else if (routeType === 'Landing Page') {
                                        return (<Route key={index} exact path={route.linkUrl} component={() => <LandingPage pageConfig={config} path={window.location.pathname} />} />);
                                    } else if (routeType === 'Form Page') {
                                        return (<Route key={index} exact path={route.linkUrl} component={() => <FormPage pageConfig={config} path={window.location.pathname} />} />);
                                    } else {
                                        return (null);
                                    }
                                })}
                                <Route component={Error404} pageConfig={config} />
                            </Switch>
                            <FooterTemplate routes={routes} pageConfig={config} />
                        </Fragment>
                    </Router>
                </Provider>
            );
        } else {
            return (null);
        }
    }
}

export default App;
