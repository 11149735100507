import React from 'react';
import classnames from 'classnames';

const FormInputTextArea = ({name, value, label, placeholder, type, valueOptions, helpText, onChange, error}) => {
    return (
        <div className="form-group row border-bottom pb-2">
            <label htmlFor={name} className="col-sm-12">{label}</label>
            <div className="col-sm-12">
                <textarea
                    name={name}
                    id={name}
                    className={classnames('form-control form-control-lg', { 'is-invalid': error})}
                    onChange={onChange}
                    aria-describedby={name+"Help"}
                    placeholder={placeholder}
                    value={value}
                    rows="6"
                />
            </div>
            {helpText && <small id={name + "Help"} className="form-text text-muted">{helpText}</small>}
            {error && <div className="col-12 alert alert-danger"><i className="fas fa-arrow-alt-circle-up" /> {error}</div>}
        </div>
    )
};

export default FormInputTextArea
