import React, {Component, Fragment} from 'react';
import Index from "../Widgets/FeaturedBoxes";
import CMATIONWeatherWidget from "../Widgets/Weather";
import CMATIONNewsWidget from "../Widgets/News";

class AdditionalContent extends Component {
    render() {
        const {additionalContent, pageConfig, additionalContentList, featuredBoxesList}=this.props;
        let contentReordered = [];
        contentReordered[0]=0;
        additionalContent.map((content, index) =>
        {
            if (index >= 2)
            {
                let contentSplit = content.split(",");
                contentReordered[contentSplit[1]]=parseInt(contentSplit[0]);
            }
            return(null);
        });

        return (
            <Fragment>
                {contentReordered.map((content, i) => {
                    if (i >= 1) {
                        return(
                            <Fragment key={i}>
                                {additionalContentList.map((ac, index) => {
                                    if (parseInt(ac.id) === contentReordered[i])
                                    {
                                        if (ac.name === 'Featured Boxes')
                                        {
                                            return (<Index
                                                key={index}
                                                title={ac.title}
                                                list={featuredBoxesList}
                                            />);
                                        }
                                        else if (ac.name === "Weather")
                                        {
                                            return (<CMATIONWeatherWidget
                                                key={index}
                                                title={ac.title}
                                                weatherAPI={pageConfig.weatherDarkskyapi}
                                                weatherCity={pageConfig.weatherCityName}
                                                weatherZip={pageConfig.weatherZipcode}
                                                weatherLat={pageConfig.weatherLat}
                                                weatherLong={pageConfig.weatherLong}
                                            />);
                                        }
                                        else if (ac.name === "News")
                                        {
                                            return (<CMATIONNewsWidget
                                                key={index}
                                                title={ac.title}
                                                newsAPI={pageConfig.newsNewsapiOrgApi}
                                                newsSearchTerm={pageConfig.newsSearchTerm}
                                            />);
                                        }
                                        else
                                        {
                                            return(null);
                                        }
                                    }
                                    else
                                    {
                                        return(null);
                                    }
                                })}
                            </Fragment>
                        );
                    } else {
                        return(null);
                    }
                })}
            </Fragment>
        );
    }
}

export default AdditionalContent