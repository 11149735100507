import React, {Component, Fragment} from 'react';

class Listings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listings: {}
        };
    };

    render() {
        const { listings } = this.props;
        return (<div className="container">
            <div className="row">
                <div className="col-12">
                    {typeof(listings) !== 'undefined' && <Fragment>
                        {listings.map((listing, index) => {
                            return (
                                <div className="row py-4 border-top" key={index}>
                                    <div className="col-12 col-sm-4">
                                        <a href={listing.externalLinkURL} title={listing.businessName} target="_blank"><img src={"https://www.eurekaspringstouristcenter.com/assets/images/" + listing.listingPhoto} alt={listing.businessName} className="img-fluid" /></a>
                                    </div>
                                    <div className="col">
                                        <h4 className="p-0 m-0"><a href={listing.externalLinkURL} className="text-success" target="_blank"><b>{listing.businessName}</b></a></h4>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-12 col-sm-4 small py-2">
                                                    <p>
                                                        {listing.tollFree && <Fragment>toll-free: {listing.tollFree}<br/></Fragment>}
                                                        {listing.phone && <Fragment>phone: {listing.phone}</Fragment>}
                                                    </p>
                                                    <p>
                                                        {listing.address1 && <Fragment>{listing.address1}, {' '}<br/></Fragment>}
                                                        {listing.address2 && <Fragment>{listing.address2}, {' '}<br/></Fragment>}
                                                        {listing.city && <Fragment>{listing.city}</Fragment>}
                                                        {listing.address1 !== '' && listing.city !== '' && listing.state && <Fragment>, {' '} {listing.state} {' '}</Fragment>}
                                                        {listing.zip !== "0" && listing.zip !== 0 && listing.zip !== "" && <Fragment>{listing.zip}</Fragment>}
                                                    </p>
                                                </div>
                                                <div className="col-12 col-sm-8 small py-2" dangerouslySetInnerHTML={{__html: listing.listingDescription}}/>
                                            </div>
                                        </div>
                                        <a href={listing.externalLinkURL} title={listing.businessName} target="_blank" className="btn btn-sm btn-success small">Visit Website</a>
                                    </div>
                                </div>
                            );
                        })}
                    </Fragment>}
                </div>
            </div>
        </div>);
    }
}

export default Listings