import React, {Component, Fragment} from 'react';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import ReactGA from "react-ga";
import axios from "axios";
import {apiKey, apiURL} from "../../config";
import {Parallax} from "react-parallax";
import Listings from "./components/Listings";
import SubCategoryList from "./components/SubCategoryList";
import CMATIONWeatherWidget from "../Widgets/Weather";
import CMATIONNewsWidget from "../Widgets/News";
import RotatingHeader from "react-image-gallery";
import Index from "../Widgets/FeaturedBoxes";
import AdSense from "react-adsense";

class ListingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category: {},
            content: [],
            listings: [],
            subCategories: [],
            rotatingImages: {},
            widgets: {},
            timestamp: 0,
            pageType: ''
        };
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState === this.state) {
            this.getContent();
        }
    }
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        this.getContent();
    }
    getContent = () => {
        let path = '';
        if (typeof(this.props.match) === 'undefined') {
            path = this.props.path;
        } else {
            path = this.props.match.path;
        }

        axios.get(apiURL + 'getLandingPage', {params: {apiKey: apiKey, path: path}})
            .then( (response) => {
                if (typeof(response.data.page) !== 'undefined') {
                    this.setState({
                        "category": response.data.category,
                        "content": response.data.page,
                        "listings": response.data.listings,
                        "subCategories": response.data.subCategories,
                        "rotatingImages": response.data.rotatingImages,
                        "widgets": response.data.widgets,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    render() {
        const { category, content, listings, subCategories, rotatingImages, widgets, timestamp } = this.state;
        const { pageConfig } = this.props;
        if (typeof(content.loaded) === 'undefined') {
            return null;
        } else {
            return (
                <Fragment>
                    <Helmet>
                        <title>{content.metaTitle}</title>
                        <meta name="description" content={content.metaDescription} />
                        <meta name="robots" content={content.metaRobots} />
                        <meta name="googlebot" content={content.metaGoogleBot} />
                        <meta name="keywords" content={content.metaKeywords} />
                        <meta name="theme-color" content="#008f68" />
                    </Helmet>

                    {typeof(widgets.rotatingHeaderImage) !== 'undefined' && <Fragment>
                        {rotatingImages && <RotatingHeader
                            items={rotatingImages}
                            showBullets={false}
                            showPlayButton={false}
                            showThumbnails={false}
                            showFullscreenButton={false}
                            autoPlay={true}
                            slideInterval={4000}
                            slideDuration={500}
                            additionalClass="headerImage no-mobile"
                        />}
                    </Fragment>}


                    {typeof(widgets.rotatingHeaderImage) === 'undefined' && <Parallax
                        bgImage={pageConfig.pageHeaderBackground}
                        bgImageAlt={"Header Image"}
                        strength={700}
                        blur={{ min: -15, max: 15 }}
                    >
                        <div className="container my-4 py-4">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="text-center my-1">EurekaSpringsTouristCenter.Com<br/>
                                        {content.title}</h1>
                                </div>
                            </div>
                        </div>
                    </Parallax>}

                    <div className="container-fluid bg-light py-5">
                        <div className="container rounded bg-white py-5">
                            <div className="row">
                                <div className="col-12">
                                    <h1>{content.title}</h1>
                                    <div dangerouslySetInnerHTML={{__html: content.content}} />
                                </div>
                            </div>

                            {content.pageType === 'mainCategoryPage' && <div className="row">
                                <div className="col-12">

                                    {category.parentName !== '' && typeof(category.parentName) !== 'undefined' && <Fragment>
                                        <Link to={category.parentUrl} className="btn btn-sm btn-success" title={category.parentName}>Back to {category.parentName}</Link>
                                        <div className="col-12">&nbsp;</div>
                                    </Fragment>}

                                    {subCategories.length !== 0 && <Fragment>
                                        <h3>Sub-categories immediately below, full list of {category.title} below that</h3>
                                        <SubCategoryList
                                            subCategories={subCategories}
                                        />
                                    </Fragment>}

                                    <div className="col-12">&nbsp;</div>

                                    <Listings
                                        listings={listings}
                                    />
                                </div>
                            </div>}

                            <div className="row">
                                <div className="col-12">
                                    {typeof(widgets.featuredBoxes) !== 'undefined' && <Index
                                        list={widgets.featuredBoxes}
                                    />}
                                    {typeof(widgets.weather) !== 'undefined' && <CMATIONWeatherWidget
                                        data={widgets.weather}
                                    />}
                                    {typeof(widgets.news) !== 'undefined' && <CMATIONNewsWidget
                                        data={widgets.news}
                                    />}
                                </div>
                                <div className="col-sm-12 my-4">
                                    <AdSense.Google
                                        client='ca-pub-2395683201327556'
                                        slot='3846590389'
                                        style={{ display: 'block' }}
                                        format='auto'
                                        responsive='true'
                                        layoutKey='-gw-1+2a-9x+5c'
                                    />
                                </div>
                            </div>


                        </div>
                    </div>
                </Fragment>
            );
        }
    }
}

export default ListingPage