import React, {Component, Fragment} from 'react';
import {Helmet} from "react-helmet";
import ReactGA from "react-ga";
import axios from "axios";
import {apiKey, apiURL} from "../../config";
import AdditionalContent from "../AdditionalContent/AdditionalContent";
import {Parallax} from "react-parallax";
import headerImage from "../../assets/images/header2.jpg";
import FormInput from "../../form/FormInput";
import Button from "react-bootstrap/Button";

class ContentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: [],
            formFields: [],
            success: 0
        };
        this.handleFlipClick = this.handleFlipClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState === this.state) {
            this.getContent();
        }
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        this.getContent();
    }

    getContent = () => {
        axios.get(apiURL + 'getPage', {params: {apiKey: apiKey, path: this.props.match.path, pageType: 'form'}})
            .then( (response) => {
                if (typeof(response.data.page) !== 'undefined') {
                    this.setState({
                        "content": response.data.page,
                        "formFields": response.data.formFields,
                        "formConfig": response.data.formConfig,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    handleFlipClick = (e) => {
        e.preventDefault();
        this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
    };

    onChange = (e) => {
        let newName = e.target.name;
        let newValue = e.target.value;
        this.setState({
            success: 0,
            formFields: this.state.formFields.map((s, index) => {
                if (this.state.formFields[index].name === newName) return { ...s, value: newValue };
                return { ...s };
            })
        });
    };

    submitForm = () => {
        let errors = 0;
        this.setState({
            success: 0,
            formFields: this.state.formFields.map((field, index) => {
                if (field.required === '1' && (field.value === '' || typeof(field.value) === 'undefined')) {
                    errors = 1;
                    return {...field, error: field.errorMessage};
                } else return {...field};
            })
        });

        if (errors !== 1) {
            axios.get(apiURL + 'submitForm', {params: {apiKey: apiKey, path: this.props.match.path, formFields: this.state.formFields}})
                .then( (response) => {
                    if (typeof(response.data.success) !== 'undefined') {
                        this.setState({
                            success: 1,
                            formFields: this.state.formFields.map((field, index) => {
                                return {...field, value: ''};
                            })
                        });
                        window.scrollTo(0, 0);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    };

    render() {
        const { content, formFields, formConfig, success } = this.state;
        if (typeof(content.id) === 'undefined') {
            return(null);
        } else {
            return (
                <Fragment>
                    <Helmet>
                        <title>{content.metaTitle}</title>
                        <meta name="description" content={content.metaDescription} />
                        <meta name="robots" content={content.metaRobots} />
                        <meta name="googlebot" content={content.metaGoogleBot} />
                        <meta name="keywords" content={content.metaKeywords} />
                        <meta name="theme-color" content="#008f68" />
                    </Helmet>
                    <Parallax
                        bgImage={headerImage}
                        bgImageAlt={"Header Image"}
                        strength={700}
                        blur={{ min: -15, max: 15 }}
                    >
                        <div className="container my-4 py-4">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="text-center text-white my-5 text-uppercase">{content.pageTitle}</h1>
                                </div>
                            </div>
                        </div>
                    </Parallax>
                    <div className="container-fluid bg-light py-5">
                        <div className="container rounded bg-white py-5">
                            {success === 1 &&
                            <div className="row">
                                <div className="col-12 px-5">
                                    <div className="alert alert-success"><b>SUCCESS</b>: Form has been submitted</div>
                                </div>
                            </div>
                            }
                            <div className="row">
                                <div className="col-sm-4">
                                    <div dangerouslySetInnerHTML={{__html: content.pageContent}} />
                                </div>
                                <div className="col-sm-8">
                                    {formFields.map((field, index) => {
                                        let fieldType = '';
                                        if (field.formsbuilderFieldsType === '1') {
                                            fieldType = 'text';
                                        } else if (field.formsbuilderFieldsType === '2') {
                                            fieldType = 'textarea';
                                        }

                                        let fieldValue = '';
                                        if (field.value !== '' && typeof(field.value) !== 'undefined') {
                                            fieldValue = field.value;
                                        }

                                        return (<FormInput
                                            key={index}
                                            name={field.name}
                                            type={fieldType}
                                            label={field.name}
                                            placeholder={field.name}
                                            value={fieldValue}
                                            error={field.error}
                                            onChange={this.onChange}
                                        />);
                                    })}
                                    <Button onClick={this.submitForm}>{formConfig.buttonText}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <AdditionalContent
                        additionalContent={content.additionalContent.split(";")}
                        additionalContentList={content.additionalContentList}
                        featuredBoxesList={content.featuredBoxesList}
                    />
                </Fragment>
            );
        }
    }
}

export default ContentPage