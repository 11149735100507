import React, {Component, Fragment} from 'react';

class SubCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subCategorys: {}
        };
    };

    render() {
        const { subCategories } = this.props;
        return (<div className="container">
            <div className="row">
                {typeof(subCategories) !== 'undefined' && <Fragment>
                    {subCategories.map((subCategory, index) => {
                        return (<div className="col-sm-4 col-12" key={index}>
                            <h4 className="p-0 m-0"><a href={subCategory.linkUrl} className="text-success"><b>{subCategory.name}</b></a></h4>
                        </div>);
                    })}
                </Fragment>}
            </div>
        </div>);
    }
}

export default SubCategory