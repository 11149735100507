import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class Header extends Component {
    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
        };
    }
    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }
    render() {
        const collapsed = this.state.collapsed;
        const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed ml-auto' : 'navbar-toggler navbar-toggler-right ml-auto';
        const { routes, pageConfig } = this.props;
        return (
            <header className="container-fluid m-0 py-2 navbar-dark bg-green text-light">
                <div className="container">
                    <div className="row">
                        <div className="col-12 px-0">
                            <nav className="navbar navbar-expand-lg">
                                <button onClick={this.toggleNavbar} className={`${classTwo}`} type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon" />
                                </button>

                                <div className={`${classOne}`} id="navbarSupportedContent">
                                    <ul className="navbar-nav ml-auto mr-auto">
                                        {routes.map((route, index) => {
                                            if (route.active === "1" && route.navItem === '1') { return (<li key={index} className="nav-item"><Link className="nav-link text-white" title={"Navigation link to " + route.name} onClick={this.toggleNavbar} to={route.linkUrl}>{route.name}</Link></li>); }
                                            else { return(null); }
                                        })}
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default Header