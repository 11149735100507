import React, {Component} from 'react';
import axios from "axios";

class CMATIONNewsWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newsDataLocal: {
                "status": "ok",
                "totalResults": 70,
                "articles": []
            },
            newsDataTop: {},
            localAPI: 0
        };
    };
    componentDidMount() {
        this.getNews();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log("componentDidUpdate");
        if (prevState === this.state) {
            // console.log("componentDidUpdate this");
        }
    }
    getNews = () => {
        axios.get('https://newsapi.org/v2/everything?q=' + this.props.data.searchTerm + '&apiKey=' + this.props.data.apiKey)
            .then((response) => {
                // console.log(response);
                if (typeof (response.data) !== 'undefined') {
                    this.setState({newsDataLocal: response.data});
                }
            })
            .catch(function (error) { console.log(error); });
    };

    render() {
        const { newsDataLocal } = this.state;
        return (
            <div className="container-fluid py-5">
                <div className="container">
                    <div className="row">
                        <h5 className="h2 col-12">local arkansas news</h5>
                        {newsDataLocal.articles.map((article, index) => {
                            if (article.urlToImage !== '' && typeof(article.urlToImage) === 'string')
                            {
                                return (
                                    <div key={index} className="col-sm-4">
                                        <div className="card mb-3">
                                            <a href={article.url} target="_blank" rel="noopener noreferrer"><img src={article.urlToImage} border="0" alt={"image for the article " + article.title} style={{maxHeight: '200px'}} className="card-img-top" /></a>
                                            <div className="card-body">
                                                <h5 className="card-title">{article.title}</h5>
                                                <p className="card-text">{article.description}</p>
                                                <div className="row">
                                                    <div className="text-left col-6"><small>by {article.source.name}</small></div>
                                                    <div className="text-right col-6"><a href={article.url} target="_blank" rel="noopener noreferrer" className="btn btn-primary">Read More</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            else
                            {
                                return(null);
                            }
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default CMATIONNewsWidget