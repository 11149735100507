import React, { Component} from 'react';
import classnames from 'classnames';

class FormInputImage extends Component {
    render() {
        const {name, value, label, placeholder, helpText, onUpload, onDeleteImage, error} = this.props;
        const BaseImageURL = window.BaseImageURL;
        return (<div className="form-group row border-bottom pb-2">
            <label className="col-12" htmlFor={name}>{label}</label>
            {value}<br />
            {value && <div className="col-12 py-2 text-center"><img src={BaseImageURL + value} border="2" className="img-fluid" alt="" /></div> }
            {value && <div className="col-12 py-2 text-center"><button className="btn btn-danger" onClick={() => onDeleteImage(name)}>Delete Image</button></div> }
            <div className="col-12">
                <input
                    id={name}
                    name={name}
                    type="file"
                    className={classnames('form-control', { 'is-invalid': error})}
                    onChange={onUpload}
                    aria-describedby={name+"Help"}
                    placeholder={placeholder}
                />
            </div>
            {helpText && <small id={name + "Help"} className="col-12 form-text text-muted">{helpText}</small>}
            {error && <div className="col-12 alert alert-danger"><i className="fas fa-arrow-alt-circle-up" /> {error}</div>}
        </div>)
    }
}

export default FormInputImage