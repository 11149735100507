import React from 'react';
import classnames from "classnames";

const FormInputRadio = ({name, value, label, placeholder, type, helpText, onChange, error, valueOptions}) => {
    let optionsValue = parseInt(value);
    return (
        <div className="form-group row border-bottom pb-2">
            <label htmlFor={name} className="col-sm-4">{label}</label>
            <div className="col-sm-8">
                {valueOptions ? (
                    valueOptions.map((options, index) =>
                        <div key={index} className="form-check form-check-inline">
                            <input
                                className={classnames('form-check-input', { 'is-invalid': error})}
                                type="radio"
                                defaultChecked={optionsValue === parseInt(options.value) ? 'checked' : null }
                                name={name}
                                id={name + index}
                                value={options.value}
                                onChange={onChange}
                            />
                            <label className="form-check-label" htmlFor={name + index}>
                                {options.name}
                            </label>
                        </div>
                    ))
                    : null
                }
            </div>
            {helpText && <small id={name+"Help"} className="col-12 form-text text-muted">{helpText}</small>}
            {error && <div className="col-12 alert alert-danger"><i className="fas fa-arrow-alt-circle-up" /> {error}</div> }
        </div>
    )
};

export default FormInputRadio