import React, {Component} from 'react';
import uploading from '../../assets/images/gears.gif';
class Uploading extends Component {
    render() {
        return (
            <div className="text-center">
                <img src={uploading} alt="Loading" style={{width: '100px', margin: 'auto', maxWidth: '100%', height: 'auto'}} />
            </div>
        )
    }
}
export default Uploading;