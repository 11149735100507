import React, {Component} from 'react';
import { Editor } from '@tinymce/tinymce-react';

class FormInputEditor extends Component {
    render() {
        const {name, value, label, handleEditorChange, helpText, error} = this.props;
        return (
            <div className="form-group row border-bottom pb-2">
                <label htmlFor={name} className="col-sm-12">{label}</label>
                <Editor
                    className="col-sm-12"
                    apiKey='r7zawome3jz6v10sen489g702inbdx7hexim6spf3cy9b550'
                    initialValue={value}
                    init={{
                        height: 350,
                        plugins: 'link image code',
                        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
                    }}
                    onChange={handleEditorChange}
                />
                {helpText && <small id={name + "Help"} className="form-text text-muted">{helpText}</small>}
                {error && <div className="col-12 alert alert-danger"><i className="fas fa-arrow-alt-circle-up" /> {error}</div>}
                <hr />
            </div>
        )
    }
}

export default FormInputEditor;
